html,
body,
#__next,
#__next > div {
  height: 100%;
}

body {
  overflow-x: hidden;
}
